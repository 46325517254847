<template>
  <section id="recommended" class="home py-4">
    <div class="container-fluid">
      <div class="container">
        <div class="col-12 col-sm-12 col-lg-12">
          <div class="row pl-2 pt-4 bg-white">
            <div class="col-12 pb-2">
              <img
                data-v-6da2d6c6=""
                src="http://motoupa.com.uy/wp-content/uploads/2017/06/logo-1.png"
                title="Moto Upa Auxilio de Motos en Montevideo"
                class="navbar-brand-logo navbar-brand-logo-normal"
                style="max-height: 60px; display: inline"
              />
            </div>
          </div>
          <div class="row pl-2 mb-4 bg-white">
            <span data-v-6da2d6c6="" class="contact100-form-title">
              Formulario para Hacerte Socio</span
            >
          </div>
          <slot v-if="step === 1">
            <div class="row pl-2 mb-4">
              <label class="col-sm-12 text-left"
                >Completando y abonando el siguiente formulario, Ud. quedará
                habilitado para recibir los servicios de forma automática
                brindados por UPA MOTOS
                <a href="https://motoupa.com.uy/informacion" target="_blank"
                  ><strong>(leer preguntas frecuentes)</strong></a
                ></label
              >
              <div class="col-12 pt-2 pb-2 bg-white">
                <h2>${{ this.monthValue() }} <small>pesos mensuales</small></h2>
                <div class="row text-center">
                  <div class="col-12">
                    <img
                      alt="Abitab"
                      src="/img/motoupa_uy_logo_abitab.jpg"
                      style="max-height: 35px; padding-right: 10px;"
                    />
                    <img
                      alt="Redpagos"
                      src="/img/motoupa_uy_logo_redpagos.png"
                      style="max-height: 35px; padding-right: 10px;"
                    />
                    <img
                      alt="Tarjeta de Crédito Visa"
                      src="/img/motoupa_uy_logo_visa_credito.jpg"
                      style="max-height: 35px"
                    />
                    <img
                      alt="Tarjeta de Crédito Mastercard"
                      src="/img/motoupa_uy_logo_mastercard.jpg"
                      style="max-height: 35px"
                    />
                    <img
                      alt="Tarjeta de Crédito Oca"
                      src="/img/motoupa_uy_logo_oca.jpg"
                      style="max-height: 35px"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row pl-2">
              <label class="col-sm-2">Nombre completo:</label>
              <div class="input-group col-sm-4 mb-2 mt-0 pt-0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nombre Completo"
                  aria-describedby="basic-addon2"
                  id="fullName"
                  name="fullName"
                  v-model.trim="$v.fullName.$model"
                  :class="{ 'input-error': submitted && $v.fullName.$error }"
                />
                <div
                  class="col-12 caption-danger"
                  v-if="submitted && !$v.fullName.required"
                >
                  <span class="text-danger">Ingrese su nombre completo</span>
                </div>
              </div>
              <label class="col-sm-2">Teléfono:</label>
              <div class="input-group col-sm-4 mb-2 mt-0 pt-0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="09X XXX XXX"
                  aria-describedby="basic-addon2"
                  id="phone"
                  name="phone"
                  v-model.trim="$v.phone.$model"
                  :class="{ 'input-error': submitted && $v.phone.$error }"
                />
                <div
                  class="col-12 caption-danger"
                  v-if="submitted && !$v.phone.required"
                >
                  <span class="text-danger">Ingrese su celular</span>
                </div>
              </div>
            </div>

            <div class="row pl-2">
              <label class="col-sm-2">Cédula de Identidad:</label>
              <div class="input-group col-sm-4 mb-2 mt-0 pt-0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Cédula de Identidad"
                  aria-describedby="basic-addon2"
                  id="personalID"
                  name="personalID"
                  maxlength="8"
                  v-model.trim="$v.personalID.$model"
                  :class="{ 'input-error': submitted && $v.personalID.$error }"
                />
                <div
                  class="col-12 caption-danger"
                  v-if="submitted && !$v.personalID.required"
                >
                  <span class="text-danger">Ingrese su cédula</span>
                </div>
              </div>
              <label class="col-sm-2">Fecha de Nacimiento:</label>
              <div class="input-group col p-xl-0 mb-2">
                <select
                  class="custom-select"
                  id="dayBirth"
                  v-model.trim="$v.dayBirth.$model"
                  :class="{
                    'input-error': submitted && !this.$v.dayBirth.minValue,
                  }"
                >
                  <option :value="0" selected>Día</option>
                  <option
                    v-for="(item, index) in 31"
                    :key="index"
                    :value="parseInt(index + 1)"
                  >
                    {{ parseInt(index + 1) }}
                  </option>
                </select>
                <div
                  class="col-12 caption-danger"
                  v-if="submitted && this.$v.dayBirth.$invalid"
                >
                  <span class="text-danger">Seleccione un día</span>
                </div>
              </div>
              <div class="input-group col p-xl-0 mb-2">
                <select
                  class="custom-select"
                  id="monthBirth"
                  v-model.trim="$v.monthBirth.$model"
                  :class="{ 'input-error': submitted && $v.monthBirth.$error }"
                >
                  <option :value="null" selected>Mes</option>
                  <option
                    v-for="(item, index) in 12"
                    :key="index"
                    :value="parseInt(index + 1)"
                  >
                    {{ parseInt(index + 1) }}
                  </option>
                </select>
                <div
                  class="col-12 caption-danger"
                  v-if="submitted && this.$v.monthBirth.$invalid"
                >
                  <span class="text-danger">Seleccione un Mes</span>
                </div>
              </div>
              <div class="input-group col mb-2 mt-0 pt-0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Año"
                  aria-describedby="basic-addon2"
                  id="yearBirth"
                  name="yearBirth"
                  v-model.trim="$v.yearBirth.$model"
                  :class="{ 'input-error': submitted && $v.yearBirth.$error }"
                />
                <div
                  class="col-12 caption-danger"
                  v-if="submitted && this.$v.yearBirth.$invalid"
                >
                  <span class="text-danger">Ingrese su año</span>
                </div>
              </div>
            </div>

            <div class="row pl-2">
              <label class="col-sm-2">Dirección:</label>
              <div class="input-group col-sm-4 mb-2 mt-0 pt-0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Dirección completa"
                  aria-describedby="basic-addon2"
                  id="address"
                  name="address"
                  v-model.trim="$v.address.$model"
                  :class="{ 'input-error': submitted && $v.address.$error }"
                />
                <div
                  class="col-12 caption-danger"
                  v-if="submitted && !$v.address.required"
                >
                  <span class="text-danger">Ingrese una dirección</span>
                </div>
              </div>
              <label class="col-sm-2">Ciudad:</label>
              <div class="input-group col p-xl-0 mb-2">
                <select
                  class="custom-select"
                  id="city"
                  v-model.trim="$v.city.$model"
                  :class="{ 'input-error': submitted && $v.city.$error }"
                >
                  <option :value="10" selected>Montevideo</option>
                  <option :value="2">Canelones</option>
                  <option :value="9">Maldonado</option>
                  <option :value="16">San José</option>
                </select>
                <div
                  class="col-12 caption-danger"
                  v-if="submitted && this.$v.city.$invalid"
                >
                  <span class="text-danger">Seleccione una ciudad</span>
                </div>
              </div>
            </div>

            <div class="row pl-2">
              <label class="col-sm-2">Mail: <small>(opcional)</small></label>
              <div class="input-group col mb-2 mt-0 pt-0">
                <input
                  type="mail"
                  class="form-control"
                  placeholder="mail@gmail.com"
                  aria-describedby="basic-addon2"
                  id="mail"
                  name="mail"
                  v-model.trim="$v.mail.$model"
                  :class="{ 'input-error': submitted && $v.mail.$error }"
                />
                <div
                  class="col-12 caption-danger"
                  v-if="submitted && !$v.mail.email"
                >
                  <span class="text-danger">Ingrese un email correcto</span>
                </div>
              </div>
            </div>
          </slot>

          <slot v-if="step === 2">
            <div class="row pl-2">
              <h2>Datos de la Moto:</h2>
            </div>
            <div class="row pl-2">
              <label class="col-sm-2">Marca:</label>
              <div class="input-group col-sm-4 mb-2 mt-0 pt-0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Marca"
                  aria-describedby="basic-addon2"
                  id="mark"
                  name="mark"
                  v-model.trim="$v.mark.$model"
                  :class="{ 'input-error': submitted && $v.mark.$error }"
                />
                <div
                  class="col-12 caption-danger"
                  v-if="submitted && !$v.mark.required"
                >
                  <span class="text-danger">Ingrese la marca</span>
                </div>
              </div>
              <label class="col-sm-2">Modelo:</label>
              <div class="input-group col-sm-4 mb-2 mt-0 pt-0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Modelo"
                  aria-describedby="basic-addon2"
                  id="model"
                  name="model"
                  v-model.trim="$v.model.$model"
                  :class="{ 'input-error': submitted && $v.model.$error }"
                />
                <div
                  class="col-12 caption-danger"
                  v-if="submitted && !$v.model.required"
                >
                  <span class="text-danger">Ingrese un modelo</span>
                </div>
              </div>
            </div>
            <div class="row pl-2">
              <label class="col-sm-2">Año:</label>
              <div class="input-group col-sm-4 mb-2 mt-0 pt-0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Año de la moto"
                  aria-describedby="basic-addon2"
                  id="year"
                  name="year"
                  v-model.trim="$v.year.$model"
                  :class="{ 'input-error': submitted && $v.year.$error }"
                />
                <div
                  class="col-12 caption-danger"
                  v-if="submitted && !$v.year.required"
                >
                  <span class="text-danger">Ingrese el año</span>
                </div>
              </div>
              <label class="col-sm-2">Matrícula:</label>
              <div class="input-group col-sm-4 mb-2 mt-0 pt-0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Matrícula"
                  aria-describedby="basic-addon2"
                  id="plate"
                  name="plate"
                  v-model.trim="$v.plate.$model"
                  :class="{ 'input-error': submitted && $v.plate.$error }"
                />
                <div
                  class="col-12 caption-danger"
                  v-if="submitted && !$v.plate.required"
                >
                  <span class="text-danger">Ingrese una matrícula</span>
                </div>
              </div>
            </div>
            <div class="row pl-2 mb-4">
              <terms></terms>
            </div>
            <div class="row pl-2 mb-4">
              <label class="col-sm-12 text-center" style="font-weight: 600"
                >Al hacer clic en Aceptar usted esta realizando un contrato con
                Upa Motos Auxilio por el termino de un año. Nuestra empresa esta
                afiliada al Clearing de Informes</label
              >
            </div>
          </slot>
          <slot v-if="step === 3">
            <div class="row pl-2">
              <h2>Le estamos redireccionando para hacer el pago, aguarde...</h2>
            </div>
          </slot>
          <div
            class="row pl-2 mb-4 text-center text-danger"
            v-if="errors.length"
          >
            <label class="col" style="font-weight: 600">
              {{ this.errors[0] }}
            </label>
          </div>
          <div class="row pl-2 mb-4 text-center" v-if="redirect">
            <label class="col" style="font-weight: 600">
              Estamos redireccionando para abonar la cuota...
            </label>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              <button
                type="button"
                class="btn btn-secondary mb-2 ml-2 mr-5"
                v-on:click="goBack()"
                v-if="this.step === 2"
              >
                Volver
              </button>
              <button
                type="button"
                class="btn btn-primary mb-2"
                v-on:click="checkFrom()"
                v-if="!sending && this.step === 1"
                id="btn-continuar-formulario"
              >
                Continuar
              </button>
              <button
                type="button"
                class="btn btn-primary mb-2"
                v-on:click="checkFrom()"
                v-if="!sending && this.step === 2"
              >
                Aceptar y Pagar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import {
  required,
  requiredIf,
  email,
  minValue,
  maxValue,
  minLength,
} from "vuelidate/lib/validators";
import Terms from "./Terms";
export default {
  name: "HelloWorld",
  props: {
    admin: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  components: {
    terms: Terms,
  },
  data: function() {
    return {
      step: 1,
      fullName: null,
      phone: null,
      personalID: null,
      dayBirth: 0,
      monthBirth: 0,
      yearBirth: null,
      address: null,
      city: null,
      mail: "",
      mark: null,
      model: null,
      year: null,
      plate: null,
      sending: false,
      error: false,
      success: false,
      submitted: false,
      errors: [],
      msgsSuccess: "",
      redirect: false,
      contractID: null,
      contractRandom: null,
    };
  },
  validations: {
    fullName: {
      required,
    },
    phone: {
      required,
    },
    personalID: {
      required,
    },
    dayBirth: {
      required,
      minValue: minValue(1),
      maxValue: maxValue(31),
    },
    monthBirth: {
      required,
      minValue: minValue(1),
      maxValue: maxValue(12),
    },
    yearBirth: {
      required,
      minLength: minLength(4),
    },
    address: {
      required,
    },
    city: {
      required,
    },
    mail: {
      email,
    },
    mark: {
      required,
    },
    model: {
      required,
    },
    year: {
      required,
    },
    plate: {
      required,
    },
  },
  created() {},
  methods: {
    monthValue: function() {
      return process.env.VUE_APP_FORM_VALUE;
    },
    checkFrom: function() {
      this.submitted = true;
      this.errors = [];
      if (this.step === 1) {
        this.$v.fullName.$touch();
        if (
          !this.$v.fullName.$invalid &&
          !this.$v.phone.$invalid &&
          !this.$v.personalID.$invalid &&
          !this.$v.dayBirth.$invalid &&
          !this.$v.monthBirth.$invalid &&
          !this.$v.yearBirth.$invalid &&
          !this.$v.address.$invalid &&
          !this.$v.city.$invalid &&
          !this.$v.mail.$invalid
        ) {
          if (!this.checkPhoneNumber(this.phone)) {
            this.errors.push("El celular no es correcto");
          } else {
            this.submitted = false;
            //send to server
            this.saveContract();
          }
        } else {
          dataLayer.push({ event: "error_formulario" });
          this.errors.push(
            "Algo no está correcto, verifique e intente nuevamente."
          );
        }
      } else if (this.step === 2) {
        if (
          !this.$v.year.$invalid &&
          !this.$v.mark.$invalid &&
          !this.$v.model.$invalid &&
          !this.$v.plate.$invalid
        ) {
          this.saveMotor();
        } else {
          dataLayer.push({ event: "error_formulario" });
          this.errors.push(
            "Verifique que los datos de la moto sean correctos."
          );
        }
      }
      /*this.errors = [];
      this.$v.$touch();
      if (this.$v.$invalid) {
        
      } else {
        
      }*/
    },
    goBack: async function() {
      this.step = this.step === 2 ? 1 : 2;
    },
    saveContract: function() {
      dataLayer.push({ event: "registro_completo" });
      const url = process.env.VUE_APP_API_BASE + "crearcontrato";
      const client = {
        nombre: this.fullName,
        telefono: this.phone,
        direccion: this.address,
        cedula: this.personalID,
        fecha_nacimiento:
          this.yearBirth + "/" + this.monthBirth + "/" + this.dayBirth,
        ciudad: this.city,
      };
      axios
        .post(url, {
          cliente: client,
        })
        .then((response) => {
          this.contractRandom = response.data.variable;
          this.contractID = response.data.id;
          this.step = 2;
        })
        .catch((error) => {
          this.errors.push(
            "No pudimos guardar la informacion, intente nuevamente"
          );
        });
    },
    saveMotor: function() {
      dataLayer.push({ event: "compra_completa" });
      const url = process.env.VUE_APP_API_BASE + "datosmoto";
      const contract = {
        estado: 4,
        cliente: {
          nombre: this.fullName,
          telefono: this.phone,
          cedula: this.personalID,
          fecha_nacimiento:
            this.yearBirth + "/" + this.monthBirth + "/" + this.dayBirth,
          direccion: this.address,
          ciudad: this.city,
          mail: this.mail ? this.mail : "",
        },
        formapago: 2,
        moto: {
          marca: this.mark,
          modelo: this.model,
          anyo: this.year,
          matricula: this.plate,
        },
        id: this.contractID,
        variable: this.contractRandom,
      };
      axios
        .post(url, {
          contrato: contract,
        })
        .then((response) => {
          this.redirect = true;
          this.step = 3;
          setTimeout((window.location = response.data.urlpago), 2100);
        })
        .catch((error) => {
          this.errors.push(
            "No pudimos procesar la informacion, intente nuevamente"
          );
        });
    },
    checkPhoneNumber: function(actual) {
      let re = /(\d{3})(\d{3})(\d{3})/g;
      let nuevo = actual.replace(re, "$1$2$3");
      let codigo = actual.replace(re, "$1");

      if (codigo <= 99) {
        switch (codigo) {
          case "091":
            if (nuevo.length == "9") {
              return nuevo;
            } else {
              return false;
            }
            break;
          case "092":
            if (nuevo.length == "9") {
              return nuevo;
            } else {
              return false;
            }
            break;
          case "093":
            if (nuevo.length == "9") {
              return nuevo;
            } else {
              return false;
            }
            break;
          case "094":
            if (nuevo.length == "9") {
              return nuevo;
            } else {
              return false;
            }
            break;
          case "095":
            if (nuevo.length == "9") {
              return nuevo;
            } else {
              return false;
            }
            break;
          case "096":
            if (nuevo.length == "9") {
              return nuevo;
            } else {
              return false;
            }
            break;
          case "097":
            if (nuevo.length == "9") {
              return nuevo;
            } else {
              return false;
            }
            break;
          case "098":
            if (nuevo.length == "9") {
              return nuevo;
            } else {
              return false;
            }
            break;
          case "099":
            if (nuevo.length == "9") {
              return nuevo;
            } else {
              return false;
            }
            break;
          default:
            return false;
        }
      } else {
        return false;
      }
    },
  },
  computed: {},
};
</script>

<style>
label.col-xl-6 {
  font-weight: bolder;
}
.title-new-comment {
  font-size: 1.4em;
  color: #003589;
  font-weight: bolder;
}
.input-error {
  border: 1px solid;
  border-color: rgba(217, 83, 79, 0.4);
}
.form-control,
.input-group-text,
.custom-select {
  border-radius: 0.6rem !important;
}
label.col-sm-2 {
  text-align: left !important;
  font-size: 18px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  color: #565656;
}
.form-control,
.input-group-text,
.custom-select {
  font-size: 20px;
}

.caption-danger {
  text-align: left;
  padding: 0.5rem;
  margin: 0.1rem 0;
}
@media (min-width: 768px) {
  label.col-sm-2 {
    text-align: right !important;
  }
}
.text-danger {
  display: inline-block;
  border-bottom: 1px dashed;
  color: #d9534f;
}

.btn-primary {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}

.btn-primary:hover {
  color: #fff;
  background-color: #227dc7;
  border-color: #2176bd;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #227dc7;
  border-color: #2176bd;
  box-shadow: 0 0 0 0.2rem rgba(82, 161, 225, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2176bd;
  border-color: #1f6fb2;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 161, 225, 0.5);
}
.contact100-form-title {
  display: block;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: #333333;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 40px;
}
.bg-white {
  background-color: #fff;
}
/*
http://formulario.motoupa.com.uy/#/3c26/res/8/?collection_id=null&collection_status=null&payment_id=null&status=null&external_reference=1&payment_type=null&merchant_order_id=null&preference_id=142700304-7aaf06cb-8e7d-40e7-a148-8795ca9c1303&site_id=MLU&processing_mode=aggregator&merchant_account_id=null
*/
</style>
