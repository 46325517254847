<template>
  <div class="col text-center">
    <div
      class="wrap-input100 validate-input"
      data-validate="Nombre es requerido"
    >
      <div class="row">
        <label class="col-md-12 col-xs-12 control-label font20" for="campo"
          >Términos y Condiciones</label
        >
      </div>
      <div class="row" id="terminos">
        <span class="titulo_terminos">UPA MOTOS AUXILIO - REGLAMENTO </span>
        <br />
        <p>
          <span class="titulo_terminos">1- UPA MOTOS AUXILIO</span>
          <span class="texto_terminos"
            >Brindará el servicio auxilio mecánico a motos en la vía pública las
            24 horas, los 365 días del año, a aquellas unidades que hayan sido
            asociadas mediante contrato a un año y que estén al día con sus
            cuotas.</span
          >
        </p>
        <p>
          <span class="texto_terminos">2- ZONA DE COBERTURA:</span>
          <span class="texto_terminos"
            >Las zonas y límites de cobertura están especificados en nuestra web
            WWW.MOTOUPA.COM.UY</span
          >
        </p>
        <p>
          <span class="texto_terminos">3- TRASLADOS:</span>
          <span class="texto_terminos"
            >SIN LÍMITE DE CANTIDAD. Cada traslado tendrá 15 km totalmente
            gratis, tomados desde el lugar de la incidencia (dentro de las zonas
            de cobertura). Pasado ese kilometraje se le brindará la tarifa a
            abonar por los km que excedan. Los traslados se efectúan sólo en
            caso de avería mecánica o accidente, y cuando la reparación en el
            lugar no es posible (a juicio del auxiliador). Los traslados no se
            realizaran en distancias inferiores a los 600 metros. El segundo
            traslado por la misma incidencia siempre tendrá costo.</span
          >
        </p>
        <p>
          <span class="texto_terminos">4- GOMERÍA:</span>
          <span class="texto_terminos"
            >La unidad asociada tendrá servicios sin límite con costos
            reducidos.</span
          >
        </p>
        <p>
          <span class="texto_terminos">5- MECÁNICA LIGERA:</span>
          <span class="texto_terminos"
            >Las averías cuya reparación no insuma más de 30 minutos se
            repararán en el lugar, y tendrán costo. En este concepto están
            cables de embrague, acelerador y freno; bujías, capuchones,
            reparación de cadena, etc. La falta de combustible no se considerará
            avería estando excluida de la presente cobertura. Los traslados por
            esta causa serán con costo.</span
          >
        </p>
        <p>
          <span class="texto_terminos">6- UPA MOTOS AUXILIO:</span>
          <span class="texto_terminos"
            >se reserva el derecho de admisión y permanencia.</span
          >
        </p>

        <p>
          <span class="texto_terminos">7- UPA MOTOS AUXILIO:</span>
          <span class="texto_terminos">
            Para acceder a nuestros servicios se deberá abonar una cuota de
            afiliación mas la cuota mensual, adquiriendo todos los derechos a
            las 24 horas.</span
          >
        </p>
        <p>
          <span class="texto_terminos">5- UPA MOTOS AUXILIO:</span>
          <span class="texto_terminos"
            >no se hace responsable de los accidentes de cualquier naturaleza
            que pudieran ocurrir durante la reparación o traslado del vehículo
            asociado.</span
          >
        </p>
        <p>
          <span class="texto_terminos">9:</span>
          <span class="texto_terminos"
            >El socio deberá comunicar a Upa Motos Auxilio todo cambio de
            vehículo. En caso de omisión, recibirá igualmente el auxilio,
            debiendo abonar un importe equivalente a una cuota.</span
          >
        </p>

        <p>
          <span class="texto_terminos">10:</span>
          <span class="texto_terminos"
            >Si el socio que ha solicitado el auxilio, solucionare el
            desperfecto por sí mismo y no cancelare la solicitud a la brevedad,
            perderá los derechos a los servicios gratuitos por lo que le resta
            de contrato.</span
          >
        </p>

        <p>
          <span class="texto_terminos">11:</span>
          <span class="texto_terminos"
            >La presente afiliación será válida por un plazo de 12 meses, y se
            renovará automáticamente por igual período año tras año. El socio
            podrá comunicar a Upa Motos Auxilio en forma escrita y con 30 días
            de antelación a la finalización del contrato, su voluntad de la no
            renovación del mismo. La sola utilización de los servicios brindados
            por Upa Motos Auxilio por parte del socio, posterior a la fecha del
            plazo contractual inicial o de sus renovaciones dará por renovado
            otro período contractual de 12 meses.</span
          >
        </p>

        <p>
          <span class="texto_terminos">12:</span>
          <span class="texto_terminos"
            >El precio de la cuota mensual ajustará semestralmente en enero y
            julio de cada año. El incumplimiento de tres cuotas aparejará la
            rescisión de este contrato, volviéndose exigible todo el saldo de
            las cuotas adeudadas hasta finalizar el contrato, el que se deberá
            pagar en una sola partida junto con los intereses devengados.</span
          >
        </p>
        <p>
          <span class="texto_terminos">13:</span>
          <span class="texto_terminos"
            >En caso de rescisión unilateral por parte del socio, éste deberá
            abonar a Upa Motos Auxilio el monto equivalente al importe de las
            cuotas que faltaren hasta el término del contrato. Para rescindir
            este contrato, el socio deberá concurrir personalmente a nuestras
            oficinas.</span
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "terms",
};
</script>
<style >
.camponumero{height:62px}.container22{display:block;position:relative;padding-left:35px;margin-bottom:12px;cursor:pointer;font-size:22px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.container22 input{position:absolute;opacity:0;cursor:pointer}.checkmark{position:absolute;top:0;left:0;height:35px;width:35px;background-color:#eee;border-radius:50%}.container22:hover input~.checkmark{background-color:#ccc}.container22 input:checked~.checkmark{background-color:#2196F3}.checkmark:after{content:"";position:absolute;display:none}.container22 input:checked~.checkmark:after{display:block}.container22 .checkmark:after{top:9px;left:9px;width:15px;height:15px;border-radius:50%;background:#fff}h3.texto_vigencia_cuota{font-size:35px}#terminos{text-align:justify!important;height:200px;padding:16px;background-color:#fff;overflow-y:scroll;overflow-x:hidden;border:1px solid #CCC;border-bottom:1px solid #CCC!important}.icono_seleccionar{max-height:80px}
</style>